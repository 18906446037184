// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as UsersDashboard__Course from "../types/UsersDashboard__Course.bs.js";
import * as UsersDashboard__Community from "../types/UsersDashboard__Community.bs.js";
import * as UsersDashboard__IssuedCertificate from "../types/UsersDashboard__IssuedCertificate.bs.js";

import "./UserDashboard__Root.css"
;

var partial_arg = "components.UsersDashboard__Root";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function headerSectiom(userName, preferredName, userTitle, avatarUrl, showUserEdit) {
  var name = Belt_Option.getWithDefault(preferredName, userName);
  return React.createElement("div", {
              className: "max-w-5xl mx-auto pt-12 flex items-center justify-between px-3 lg:px-0"
            }, React.createElement("div", {
                  className: "flex"
                }, avatarUrl !== undefined ? React.createElement("img", {
                        className: "w-16 h-16 rounded-full border object-cover border-gray-300 overflow-hidden shrink-0 mr-4",
                        src: avatarUrl
                      }) : React.createElement(Avatar.make, {
                        name: name,
                        className: "w-16 h-16 mr-4 border border-gray-300 rounded-full overflow-hidden shrink-0"
                      }), React.createElement("div", {
                      className: "text-sm flex flex-col justify-center"
                    }, React.createElement("div", {
                          className: "text-white font-bold inline-block"
                        }, name), React.createElement("div", {
                          className: "text-gray-50 inline-block"
                        }, userTitle))), ReactUtils.nullUnless(React.createElement("a", {
                      className: "btn",
                      href: "/user/edit"
                    }, React.createElement("i", {
                          className: "fas fa-edit text-xs md:text-sm mr-2"
                        }), React.createElement("span", undefined, t(undefined, undefined, "edit_profile"))), showUserEdit));
}

function navButtonClasses(selected) {
  return "font-semibold border-b-2 text-sm py-4 mr-6 hover:text-primary-500 hover:border-gray-300 focus:border-gray-300 focus:text-primary-500 focus:outline-none " + (
          selected ? "text-primary-500 border-primary-500" : "border-transparent"
        );
}

function navSection(view, setView, communities, issuedCertificates) {
  return React.createElement("div", {
              className: "border-b mt-6"
            }, React.createElement("div", {
                  className: "flex max-w-5xl mx-auto px-3 lg:px-0",
                  role: "tablist"
                }, React.createElement("button", {
                      "aria-selected": view === /* ShowCourses */0,
                      className: navButtonClasses(view === /* ShowCourses */0),
                      role: "tab",
                      onClick: (function (param) {
                          return Curry._1(setView, (function (param) {
                                        return /* ShowCourses */0;
                                      }));
                        })
                    }, React.createElement("i", {
                          className: "fas fa-book text-xs md:text-sm mr-2"
                        }), React.createElement("span", undefined, t(undefined, undefined, "my_courses"))), ReactUtils.nullUnless(React.createElement("button", {
                          "aria-selected": view === /* ShowCommunities */1,
                          className: navButtonClasses(view === /* ShowCommunities */1),
                          role: "tab",
                          onClick: (function (param) {
                              return Curry._1(setView, (function (param) {
                                            return /* ShowCommunities */1;
                                          }));
                            })
                        }, React.createElement("i", {
                              className: "fas fa-users text-xs md:text-sm mr-2"
                            }), React.createElement("span", undefined, t(undefined, undefined, "communities"))), ArrayUtils.isNotEmpty(communities)), ReactUtils.nullUnless(React.createElement("button", {
                          "aria-selected": view === /* ShowCertificates */2,
                          className: navButtonClasses(view === /* ShowCertificates */2),
                          role: "tab",
                          onClick: (function (param) {
                              return Curry._1(setView, (function (param) {
                                            return /* ShowCertificates */2;
                                          }));
                            })
                        }, React.createElement("i", {
                              className: "fas fa-certificate text-xs md:text-sm mr-2"
                            }), React.createElement("span", undefined, t(undefined, undefined, "certificates"))), ArrayUtils.isNotEmpty(issuedCertificates))));
}

function courseLink(href, title, icon) {
  return React.createElement("a", {
              key: href,
              className: "px-2 py-1 mr-2 mt-2 rounded text-sm bg-gray-50 text-gray-500 hover:bg-gray-50 hover:text-primary-500 focus:outline-none focus:bg-gray-50 focus:text-primary-500",
              href: href
            }, React.createElement("i", {
                  className: icon
                }), React.createElement("span", {
                  className: "font-medium ml-2"
                }, title));
}

function ctaButton(title, href) {
  return React.createElement("a", {
              className: "w-full bg-primary-50 mt-4 px-6 py-4 flex text-sm font-semibold justify-between items-center cursor-pointer text-primary-500 hover:bg-primary-100 focus:outline-none focus:bg-primary-100",
              href: href
            }, React.createElement("span", undefined, React.createElement("i", {
                      className: "fas fa-book"
                    }), React.createElement("span", {
                      className: "ml-2"
                    }, title)), React.createElement("i", {
                  className: "fas fa-arrow-right"
                }));
}

function ctaText(message, icon) {
  return React.createElement("div", {
              className: "w-full bg-red-100 text-red-600 mt-4 px-6 py-4 flex text-sm font-semibold justify-center items-center "
            }, React.createElement("span", undefined, React.createElement("i", {
                      className: icon
                    }), React.createElement("span", {
                      className: "ml-2"
                    }, message)));
}

function studentLink(courseId, suffix) {
  return "/courses/" + (courseId + ("/" + suffix));
}

function callToAction(course, currentSchoolAdmin) {
  if (currentSchoolAdmin) {
    return "ViewCourse";
  } else if (UsersDashboard__Course.author(course)) {
    return "EditCourse";
  } else if (UsersDashboard__Course.review(course)) {
    return "ReviewSubmissions";
  } else if (UsersDashboard__Course.exited(course)) {
    return "DroppedOut";
  } else if (UsersDashboard__Course.ended(course)) {
    return "CourseEnded";
  } else if (UsersDashboard__Course.accessEnded(course)) {
    return "AccessEnded";
  } else {
    return "ViewCourse";
  }
}

function ctaFooter(course, currentSchoolAdmin) {
  var courseId = UsersDashboard__Course.id(course);
  var match = callToAction(course, currentSchoolAdmin);
  if (match === "ReviewSubmissions") {
    return ctaButton(t(undefined, undefined, "cta.review_submissions"), studentLink(courseId, "review"));
  } else if (match === "ViewCourse") {
    return ctaButton(t(undefined, undefined, "cta.view_course"), studentLink(courseId, "curriculum"));
  } else if (match === "EditCourse") {
    return ctaButton(t(undefined, undefined, "cta.edit_curriculum"), "/school/courses/" + (courseId + "/curriculum"));
  } else if (match === "CourseEnded") {
    return ctaText(t(undefined, undefined, "cta.course_ended"), "fas fa-history");
  } else if (match === "DroppedOut") {
    return ctaText(t(undefined, undefined, "cta.dropped_out"), "fas fa-user-slash");
  } else {
    return ctaText(t(undefined, undefined, "cta.access_ended"), "fas fa-history");
  }
}

function communityLinks(communityIds, communities) {
  return communityIds.map(function (id) {
              var community = communities.find(function (c) {
                    return UsersDashboard__Community.id(c) === id;
                  });
              if (community !== undefined) {
                return React.createElement("a", {
                            key: UsersDashboard__Community.id(community),
                            className: "px-2 py-1 mr-2 mt-2 rounded text-sm bg-gray-50 text-gray-500 hover:bg-gray-50 hover:text-primary-500 focus:outline-none focus:bg-gray-50 focus:text-primary-500",
                            href: UsersDashboard__Community.path(community)
                          }, React.createElement("i", {
                                className: "fas fa-users"
                              }), React.createElement("span", {
                                className: "font-medium ml-2"
                              }, UsersDashboard__Community.name(community)));
              } else {
                return null;
              }
            });
}

function courseLinks(course, currentSchoolAdmin, communities) {
  var courseId = UsersDashboard__Course.id(course);
  var cta = callToAction(course, currentSchoolAdmin);
  return React.createElement("div", {
              className: "flex flex-wrap px-4 mt-2"
            }, ReactUtils.nullUnless(courseLink("/school/courses/" + (courseId + "/curriculum"), t(undefined, undefined, "cta.edit_curriculum"), "fas fa-check-square"), UsersDashboard__Course.author(course) && cta !== "EditCourse"), ReactUtils.nullUnless(courseLink(studentLink(courseId, "curriculum"), t(undefined, undefined, "cta.view_curriculum"), "fas fa-book"), cta !== "ViewCourse"), ReactUtils.nullUnless(courseLink(studentLink(courseId, "leaderboard"), t(undefined, undefined, "cta.leaderboard"), "fas fa-calendar-alt"), UsersDashboard__Course.enableLeaderboard(course)), ReactUtils.nullUnless(courseLink(studentLink(courseId, "review"), t(undefined, undefined, "cta.review_submissions"), "fas fa-check-square"), UsersDashboard__Course.review(course) && cta !== "ReviewSubmissions"), ReactUtils.nullUnless(courseLink(studentLink(courseId, "students"), t(undefined, undefined, "cta.my_students"), "fas fa-user-friends"), UsersDashboard__Course.review(course)), communityLinks(UsersDashboard__Course.linkedCommunities(course), communities));
}

function coursesSection(courses, communities, currentSchoolAdmin) {
  return React.createElement("div", {
              className: "w-full max-w-5xl mx-auto"
            }, ReactUtils.nullUnless(React.createElement("div", {
                      className: "flex flex-col mx-auto bg-black-900 rounded-md border p-6 justify-center items-center mt-4"
                    }, React.createElement(FaIcon.make, {
                          classes: "fas fa-book text-5xl text-gray-400"
                        }), React.createElement("h4", {
                          className: "mt-3 text-base md:text-lg text-center font-semibold"
                        }, t(undefined, undefined, "empty_courses"))), ArrayUtils.isEmpty(courses)), React.createElement("div", {
                  className: "flex flex-wrap flex-1 lg:-mx-5"
                }, courses.map(function (course) {
                      var url = UsersDashboard__Course.thumbnailUrl(course);
                      return React.createElement("div", {
                                  key: UsersDashboard__Course.id(course),
                                  "aria-label": UsersDashboard__Course.name(course),
                                  className: "w-full px-3 lg:px-5 md:w-1/2 mt-6 md:mt-10"
                                }, React.createElement("div", {
                                      key: UsersDashboard__Course.id(course),
                                      className: "overflow-hidden shadow bg-white rounded-lg flex flex-col justify-between h-full"
                                    }, React.createElement("div", undefined, React.createElement("div", {
                                              className: "relative"
                                            }, React.createElement("div", {
                                                  className: "relative pb-1/2 bg-gray-800"
                                                }, url !== undefined ? React.createElement("img", {
                                                        className: "absolute h-full w-full object-cover",
                                                        src: url
                                                      }) : React.createElement("div", {
                                                        className: "user-dashboard-course__cover absolute h-full w-full svg-bg-pattern-1"
                                                      })), React.createElement("div", {
                                                  key: UsersDashboard__Course.id(course),
                                                  className: "user-dashboard-course__title-container absolute w-full flex items-center h-16 bottom-0 z-50"
                                                }, React.createElement("h4", {
                                                      className: "user-dashboard-course__title text-white font-semibold leading-tight pl-6 pr-4 text-lg md:text-xl"
                                                    }, UsersDashboard__Course.name(course)))), React.createElement("div", {
                                              className: "user-dashboard-course__description text-sm px-6 pt-4 w-full leading-relaxed"
                                            }, UsersDashboard__Course.description(course)), UsersDashboard__Course.exited(course) && !UsersDashboard__Course.review(course) && !UsersDashboard__Course.author(course) ? React.createElement("div", {
                                                className: "text-sm py-4 bg-red-100 rounded mt-2 px-6"
                                              }, t(undefined, undefined, "course_locked_message")) : React.createElement("div", undefined, courseLinks(course, currentSchoolAdmin, communities))), React.createElement("div", undefined, ctaFooter(course, currentSchoolAdmin))));
                    })));
}

function communitiesSection(communities) {
  return React.createElement("div", {
              className: "w-full max-w-5xl mx-auto"
            }, React.createElement("div", {
                  className: "flex flex-wrap flex-1 lg:-mx-5"
                }, communities.map(function (community) {
                      return React.createElement("div", {
                                  key: UsersDashboard__Community.id(community),
                                  className: "flex w-full px-3 lg:px-5 md:w-1/2 mt-6 md:mt-10"
                                }, React.createElement("a", {
                                      className: "w-full h-full shadow rounded-lg hover:shadow-lg",
                                      href: UsersDashboard__Community.path(community)
                                    }, React.createElement("div", {
                                          className: "user-dashboard-community__cover flex w-full bg-gray-600 h-40 svg-bg-pattern-5 items-center justify-center p-4 shadow rounded-t-lg"
                                        }), React.createElement("div", {
                                          className: "w-full flex justify-between items-center bg-gray-50 rounded-b-md flex-wrap px-4 pt-2 pb-4"
                                        }, React.createElement("h4", {
                                              className: "font-bold text-sm pt-2 leading-tight"
                                            }, UsersDashboard__Community.name(community)), React.createElement("div", {
                                              className: "btn btn-small btn-primary-ghost mt-2"
                                            }, t(undefined, undefined, "cta.visit_community")))));
                    })));
}

function certificatesSection(issuedCertificates) {
  return React.createElement("div", {
              className: "w-full max-w-5xl mx-auto"
            }, React.createElement("div", {
                  className: "flex flex-wrap flex-1 lg:-mx-5"
                }, issuedCertificates.map(function (issuedCertificate) {
                      return React.createElement("div", {
                                  key: UsersDashboard__IssuedCertificate.id(issuedCertificate),
                                  className: "flex w-full px-3 lg:px-5 md:w-1/2 mt-6 md:mt-10"
                                }, React.createElement("a", {
                                      className: "w-full h-full shadow rounded-lg hover:shadow-lg",
                                      href: "/c/" + UsersDashboard__IssuedCertificate.serialNumber(issuedCertificate)
                                    }, React.createElement("div", {
                                          className: "user-dashboard-community__cover flex w-full bg-gray-600 h-40 svg-bg-pattern-5 items-center justify-center p-4 shadow rounded-t-lg"
                                        }), React.createElement("div", {
                                          className: "w-full flex justify-between items-center flex-wrap bg-gray-50 rounded-b-md px-4 pt-2 pb-4"
                                        }, React.createElement("div", undefined, React.createElement("h4", {
                                                  className: "font-bold text-sm pt-2 leading-tight"
                                                }, UsersDashboard__IssuedCertificate.courseName(issuedCertificate)), React.createElement("div", {
                                                  className: "text-xs"
                                                }, React.createElement("span", undefined, t(undefined, undefined, "issued_on")), React.createElement("span", {
                                                      className: "ml-1"
                                                    }, DateFns.formatPreset(UsersDashboard__IssuedCertificate.createdAt(issuedCertificate), true, true, undefined, undefined)))), React.createElement("div", {
                                              className: "btn btn-small btn-primary-ghost mt-2"
                                            }, t(undefined, undefined, "cta.view_certificate")))));
                    })));
}

function UsersDashboard__Root(Props) {
  var currentSchoolAdmin = Props.currentSchoolAdmin;
  var courses = Props.courses;
  var communities = Props.communities;
  var showUserEdit = Props.showUserEdit;
  var userName = Props.userName;
  var preferredName = Props.preferredName;
  var userTitle = Props.userTitle;
  var avatarUrl = Props.avatarUrl;
  var issuedCertificates = Props.issuedCertificates;
  var match = React.useState(function () {
        return /* ShowCourses */0;
      });
  var view = match[0];
  var tmp;
  switch (view) {
    case /* ShowCourses */0 :
        tmp = coursesSection(courses, communities, currentSchoolAdmin);
        break;
    case /* ShowCommunities */1 :
        tmp = communitiesSection(communities);
        break;
    case /* ShowCertificates */2 :
        tmp = certificatesSection(issuedCertificates);
        break;
    
  }
  return React.createElement("div", {
              className: "bg-black-900"
            }, React.createElement("div", {
                  className: "bg-black-100 text-white"
                }, headerSectiom(userName, preferredName, userTitle, avatarUrl, showUserEdit), navSection(view, match[1], communities, issuedCertificates)), React.createElement("div", {
                  className: "pb-8"
                }, tmp));
}

var make = UsersDashboard__Root;

export {
  t ,
  str ,
  headerSectiom ,
  navButtonClasses ,
  navSection ,
  courseLink ,
  ctaButton ,
  ctaText ,
  studentLink ,
  callToAction ,
  ctaFooter ,
  communityLinks ,
  courseLinks ,
  coursesSection ,
  communitiesSection ,
  certificatesSection ,
  make ,
  
}
/*  Not a pure module */
