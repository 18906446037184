// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as User from "../../../shared/types/User.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as CoursesStudents__CoachNote from "../types/CoursesStudents__CoachNote.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesStudents__CoachNoteShow";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation ArchiveCoachNoteMutation($id: ID!)  {\narchiveCoachNote(id: $id)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.archiveCoachNote;
  return {
          archiveCoachNote: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.archiveCoachNote;
  var value$2 = value$1.success;
  var archiveCoachNote = {
    success: value$2
  };
  return {
          archiveCoachNote: archiveCoachNote
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var ArchiveCoachNoteMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var ArchiveCoachNoteMutation_Graphql_error = include.Graphql_error;

var ArchiveCoachNoteMutation_decodeNotification = include.decodeNotification;

var ArchiveCoachNoteMutation_decodeObject = include.decodeObject;

var ArchiveCoachNoteMutation_decodeNotifications = include.decodeNotifications;

var ArchiveCoachNoteMutation_decodeErrors = include.decodeErrors;

var ArchiveCoachNoteMutation_flashNotifications = include.flashNotifications;

var ArchiveCoachNoteMutation_sendQuery = include.sendQuery;

var ArchiveCoachNoteMutation_query = include.query;

var ArchiveCoachNoteMutation_make = include.make;

var ArchiveCoachNoteMutation = {
  ArchiveCoachNoteMutation_inner: ArchiveCoachNoteMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: ArchiveCoachNoteMutation_Graphql_error,
  decodeNotification: ArchiveCoachNoteMutation_decodeNotification,
  decodeObject: ArchiveCoachNoteMutation_decodeObject,
  decodeNotifications: ArchiveCoachNoteMutation_decodeNotifications,
  decodeErrors: ArchiveCoachNoteMutation_decodeErrors,
  flashNotifications: ArchiveCoachNoteMutation_flashNotifications,
  sendQuery: ArchiveCoachNoteMutation_sendQuery,
  query: ArchiveCoachNoteMutation_query,
  $$fetch: $$fetch,
  make: ArchiveCoachNoteMutation_make
};

function removeCoachNote(id, removeNoteCB, setArchiving, $$event) {
  $$event.preventDefault();
  if (window.confirm(tr(undefined, undefined, "sure_delete"))) {
    Curry._1(setArchiving, (function (param) {
            return true;
          }));
    Curry._3($$fetch, undefined, undefined, {
            id: id
          }).then(function (response) {
          if (response.archiveCoachNote.success) {
            Curry._1(removeNoteCB, id);
          } else {
            Curry._1(setArchiving, (function (param) {
                    return false;
                  }));
          }
          return Promise.resolve(undefined);
        });
    return ;
  }
  
}

function deleteIcon(note, removeNoteCB, setArchiving, archiving) {
  var partial_arg = CoursesStudents__CoachNote.id(note);
  return React.createElement("button", {
              "aria-label": tr(undefined, undefined, "delete_note") + CoursesStudents__CoachNote.id(note),
              className: "w-10 text-sm text-gray-600 hover:text-gray-900 cursor-pointer flex items-center justify-center rounded hover:bg-gray-50 hover:text-red-500 focus:outline-none focus:bg-gray-50 focus:text-red-500 focus:ring-2 focus:ring-inset focus:ring-red-500 ",
              title: tr(undefined, undefined, "delete_note") + CoursesStudents__CoachNote.id(note),
              disabled: archiving,
              onClick: (function (param) {
                  return removeCoachNote(partial_arg, removeNoteCB, setArchiving, param);
                })
            }, React.createElement(FaIcon.make, {
                  classes: archiving ? "fas fa-spinner fa-spin" : "fas fa-trash-alt"
                }));
}

function CoursesStudents__CoachNoteShow(Props) {
  var note = Props.note;
  var userId = Props.userId;
  var removeNoteCB = Props.removeNoteCB;
  var match = React.useState(function () {
        return false;
      });
  var user = CoursesStudents__CoachNote.author(note);
  var tmp;
  if (user !== undefined) {
    var avatarUrl = User.avatarUrl(user);
    tmp = avatarUrl !== undefined ? React.createElement("img", {
            className: "w-8 h-8 md:w-10 md:h-10 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 mt-1 md:mt-0 mr-2 md:mr-3 object-cover",
            src: avatarUrl
          }) : React.createElement(Avatar.make, {
            name: User.name(user),
            className: "w-8 h-8 md:w-10 md:h-10 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 mt-1 md:mt-0 mr-2 md:mr-3 object-cover"
          });
  } else {
    tmp = React.createElement(Avatar.make, {
          name: "?",
          className: "w-8 h-8 md:w-10 md:h-10 text-xs border rounded-full overflow-hidden shrink-0 mt-1 md:mt-0 mr-2 md:mr-3 object-cover"
        });
  }
  var user$1 = CoursesStudents__CoachNote.author(note);
  var user$2 = CoursesStudents__CoachNote.author(note);
  var user$3 = CoursesStudents__CoachNote.author(note);
  var showDeleteIcon = user$3 !== undefined ? User.id(user$3) === userId : false;
  return React.createElement("div", {
              key: CoursesStudents__CoachNote.id(note),
              "aria-label": "Note " + CoursesStudents__CoachNote.id(note),
              className: "mt-4"
            }, React.createElement("div", {
                  className: "flex justify-between"
                }, React.createElement("div", {
                      className: "flex"
                    }, tmp, React.createElement("div", undefined, React.createElement("p", {
                              className: "text-sm font-semibold inline-block leading-snug"
                            }, user$1 !== undefined ? User.name(user$1) : tr(undefined, undefined, "deleted_coach")), React.createElement("p", {
                              className: "text-gray-600 text-xs mt-px leading-snug"
                            }, user$2 !== undefined ? User.fullTitle(user$2) : tr(undefined, undefined, "unknown")))), showDeleteIcon ? deleteIcon(note, removeNoteCB, match[1], match[0]) : null), React.createElement("div", {
                  className: "ml-10 md:ml-13 mt-2"
                }, React.createElement("p", {
                      className: "inline-block text-xs font-semibold leading-tight bg-gray-300 text-gray-800 mt-px px-1 py-px rounded"
                    }, tr(undefined, undefined, "coach_on") + " " + CoursesStudents__CoachNote.noteOn(note)), React.createElement(MarkdownBlock.make, {
                      markdown: CoursesStudents__CoachNote.note(note),
                      className: "pt-1 text-sm",
                      profile: /* Permissive */0
                    })));
}

var make = CoursesStudents__CoachNoteShow;

export {
  str ,
  tr ,
  ArchiveCoachNoteMutation ,
  removeCoachNote ,
  deleteIcon ,
  make ,
  
}
/* include Not a pure module */
